<!--
 * @Author: your name
 * @Date: 2021-11-19 10:32:02
 * @LastEditTime: 2021-11-22 16:57:41
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \交建\traffic-construction-web\src\views\pages\super-admin\sys-setting\safety-setting\index.vue
-->
<template>
  <div>
    <el-table :data="tableData" stripe style="width: 100%" size="medium" v-loading="tabelLoading">
      <el-table-column label="序号" width="120" fixed align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="name" label="配置项" fixed align="center" show-overflow-tooltip> </el-table-column>
      <el-table-column label="操作" fixed="right" width="200" align="center">
        <template slot-scope="{ row }">
          <span class="view-details" @click="handelConfig(row)">点击配置</span>
        </template>
      </el-table-column>
    </el-table>
    <!-- 配置dialog -->
    <config-hidden-dialog ref="hiddenRef" :dialog-visible.sync="dialogVisible"></config-hidden-dialog>
  </div>
</template>

<script>
import { getItem } from '@/utils/storage.js';
import { configurationList } from 'api/super-admin/common';
import configHiddenDialog from '../../common-config/hidden-setting/configHiddenDialog';

export default {
  components: {
    configHiddenDialog
  },
  data() {
    return {
      tableData: [],
      tabelLoading: false,
      dialogVisible: false,
      tabelParams: {
        tenantId: '',
        module: '3'
      }
    };
  },
  methods: {
    handelConfig(row) {
      this.dialogVisible = true;
      this.$refs.hiddenRef.initTabelConfigFunc(row.id, row.name);
    },
    configurationList() {
      //列表
      this.tabelLoading = true;
      configurationList(this.tabelParams).then(res => {
        if (res.code === 200) {
          this.tabelLoading = false;
          this.tableData = res.data;
        }
      });
    }
  },
  created() {
    this.tabelParams.tenantId = getItem('orgId');
    this.configurationList();
  }
};
</script>
